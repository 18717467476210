export const transformFundingRequestStatus = (
  state: string
): {
  statusVariant: string
  status: string
} => {
  switch (state) {
    case 'DRAFT':
      return { statusVariant: 'gray', status: 'Draft' }
    case 'REJECTED':
      return { statusVariant: 'error', status: 'Rejected' }
    case 'UNDER_REVIEW':
      return { statusVariant: 'warning', status: 'Pending' }
    case 'INVOICED':
      return { statusVariant: 'info', status: 'Invoiced' }
    case 'APPROVED':
      return { statusVariant: 'success', status: 'Approved' }
    default:
      return { statusVariant: 'error', status: 'Error' }
  }
}

export function normalizePhoneNumber(phoneNumber: string) {
  if (!phoneNumber) {
    return ''
  }

  const numericOnly = phoneNumber.replace(/\D/g, '')

  return `+1${numericOnly}`
}

export function convertPhoneNumberToMaskFormat(phoneNumber: string) {
  return phoneNumber.replace('+1', '')
}
