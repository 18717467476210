'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { navigationStateAtom } from '@/hooks/useCollapseMenu';
import { useAtomValue } from 'jotai';
import { cn } from '../../utils';
const TooltipProvider = TooltipPrimitive.Provider;
TooltipProvider.displayName = 'TooltipProvider';
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipContent = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>>(({
  className,
  sideOffset = 8,
  ...props
}, ref) => <TooltipPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn('z-50 overflow-hidden rounded-md border border-gray-100 bg-white px-3 py-2 text-xs text-gray-700 font-bold shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1', className)} {...props} />);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
const TooltipWithOverflow = ({
  title,
  className
}: {
  title?: string | null;
  className?: string;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const menuState = useAtomValue(navigationStateAtom);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    const checkOverflow = () => {
      if (!containerRef.current) {
        return;
      }
      setOpen(false);
      setIsOverflowing(containerRef.current.scrollWidth > containerRef.current.clientWidth);
    };
    checkOverflow();

    // Check overflow after animation is finished
    setTimeout(() => {
      checkOverflow();
    }, 500);
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [containerRef, title, menuState]);
  return <Tooltip open={open && isOverflowing} onOpenChange={setOpen} data-sentry-element="Tooltip" data-sentry-component="TooltipWithOverflow" data-sentry-source-file="index.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="index.tsx">
        <span ref={containerRef} className={cn(className, 'truncate inline-block max-w-full')}>
          {title}
        </span>
      </TooltipTrigger>
      <TooltipContent className="max-w-[250px]" data-sentry-element="TooltipContent" data-sentry-source-file="index.tsx">
        <div>{title}</div>
      </TooltipContent>
    </Tooltip>;
};
export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, TooltipWithOverflow };