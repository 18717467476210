/**
 * Truncates the middle of a string, preserving a specified number of characters
 * from the start and end, and inserts ellipses ("...") in between.
 * If the input is undefined, it returns an empty string.
 *
 * @param value - The string to be truncated, or undefined.
 * @param frontChars - The number of characters to keep at the beginning of the string. Defaults to 4.
 * @param backChars - The number of characters to keep at the end of the string. Defaults to 4.
 * @returns The truncated string with ellipses in the middle, the original string if short enough, or an empty string if the value is undefined.
 *
 * @example
 * ```typescript
 * // Returns '1234...CDEFG'
 * truncateMiddle('1234567890ABCDEFG')
 * ```
 *
 * @example
 * ```typescript
 * // Returns '12...45'
 * truncateMiddle('12345', 2, 2)
 * ```
 *
 * @example
 * ```typescript
 * // Returns ''
 * truncateMiddle(undefined)
 * ```
 */
export function truncateMiddle(
  value: string | undefined,
  frontChars: number = 4,
  backChars: number = 4
): string {
  if (!value) {
    return ''
  }
  if (value.length <= frontChars + backChars) {
    return value
  }
  const front = value.slice(0, frontChars)
  const back = value.slice(-backChars)
  return `${front}...${back}`
}
