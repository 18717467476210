'use client'

export const buildQueryParams = (params: {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | Array<string | number>
    | undefined
}) => {
  const searchParams = new URLSearchParams()
  Object.keys(params)
    .filter((key) => params[key])
    .forEach((key) => {
      if (Array.isArray(params[key])) {
        ;(params[key] as Array<string | number>)?.forEach((value) => {
          searchParams.append(key, `${value}`)
        })
      } else {
        searchParams.append(key, `${params[key]}`)
      }
    })

  return searchParams.toString()
}

export const parseQueryParams = ({
  searchParams = {},
  filter = [],
  add = []
}: {
  searchParams?: Record<string, string | string[] | number>
  filter?: string[]
  add?: { key: string; value: string }[]
}) => {
  const _searchParams = new URLSearchParams(
    new URLSearchParams(buildQueryParams(searchParams))
  )

  for (const key of filter) {
    _searchParams.delete(key)
  }

  for (const { key, value } of add) {
    _searchParams.set(key, value)
  }

  return _searchParams.toString()
}
