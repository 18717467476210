'use client'

import { atom, useAtom } from 'jotai'
import { useCookies } from 'next-client-cookies'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useMediaQuery } from './useMediaQuery'

const MENU_COLLAPSED_TRIGGER_KEY = 'collapsed-menu'

/* eslint-disable no-unused-vars */
enum MenuStates {
  OPEN = 'open',
  CLOSE = 'close'
}

export const navigationStateAtom = atom<MenuStates>(MenuStates.OPEN)

export const useCollapseMenu = () => {
  const isTablet = useMediaQuery('(max-width: 1023px)')
  const [state, setState] = useAtom(navigationStateAtom)
  const { get, set } = useCookies()
  const initialState = get(MENU_COLLAPSED_TRIGGER_KEY) as MenuStates

  const initRef = useRef(false)

  useEffect(() => {
    initRef.current = true
    setState(initialState ?? MenuStates.OPEN)
  }, [])

  useEffect(() => {
    if (isTablet) {
      set(MENU_COLLAPSED_TRIGGER_KEY, MenuStates.CLOSE)
      setState(MenuStates.CLOSE)
    }
  }, [isTablet])

  const onChange = useCallback(() => {
    switch (state) {
      case MenuStates.CLOSE:
        set(MENU_COLLAPSED_TRIGGER_KEY, MenuStates.OPEN)
        setState(MenuStates.OPEN)
        break
      case MenuStates.OPEN:
      default:
        set(MENU_COLLAPSED_TRIGGER_KEY, MenuStates.CLOSE)
        setState(MenuStates.CLOSE)
        break
    }
  }, [state])

  const isCollapsed = useMemo(() => state === MenuStates.CLOSE, [state])

  return {
    isCollapsed: initRef.current
      ? isCollapsed
      : initialState === MenuStates.CLOSE,
    onChange
  }
}
