export interface PropertyError {
  property: string
  constraints: Record<string, string>
}

export const extractFormError = ({
  errors,
  setFieldError
}: {
  errors: PropertyError[]
  setFieldError: (field: string, message: string | undefined) => void
}) => {
  const messages = errors.reduce(
    (acc, curr) => {
      if (!acc[curr.property]) {
        acc[curr.property] = []
      }

      Object.keys(curr.constraints).forEach((key) => {
        acc[curr.property].push(curr.constraints[key])
      })

      return acc
    },
    {} as Record<string, string[]>
  )

  Object.keys(messages).forEach((key) => {
    setFieldError(key, messages[key].join(', '))
  })
}
